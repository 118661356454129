import React, { useEffect } from "react";

import { ReactComponent as CloseCircleIcon } from "assets/images/close-circle.svg";

function ModalRedirect(props) {
    useEffect(() => {}, []);

    return (
        <>
            <div className="modal-body text-center notice-modal">
                <button onClick={() => props.close()} className="close-btn">
                    <CloseCircleIcon />
                </button>

                {props.data.header}

                <p className="note">{props.data.note}</p>

                <div className="button-list-container">
                    {props.data && props.data.buttons.length !== 0
                        ? props.data.buttons.map((value, id) => (
                              <a
                                  className={`button button--inverted button--hollow button--hollow--inverted ${
                                      value.class
                                  } ${
                                      !value.tooltip && "no-tooltip"
                                  } tooltip-top`}
                                  href={value.url}
                                  target={"_blank"}
                                  key={`redirect-button-${id}`}
                                  data-tooltip={
                                      value.tooltip ? value.tooltip : ""
                                  }
                                  rel="noreferrer"
                              >
                                  <span className="--text">{value.label}</span>
                              </a>
                          ))
                        : ""}
                </div>
            </div>
        </>
    );
}

export default ModalRedirect;
