import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "context/modal/ModalContext";
import Modal from "react-modal";
import ModalSaveSquare from "components/common/modal/SaveSquare";
import ModalPurchaseSquares from "components/common/modal/PurchaseSquares";
import ModalNotice from "components/common/modal/Notice";
import ModalAnnouncement from "components/common/modal/Announcement";
import { ReactComponent as CloseCircleIcon } from "assets/images/close-circle.svg";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#modal-root");
const GlobalModal = () => {
    const { modal, handleModal, modalType, data, setters } =
        useContext(ModalContext);

    const MODAL_TYPES = {
        save: (
            <ModalSaveSquare
                close={() => handleModal(false)}
                data={data}
                setters={setters}
            />
        ),
        purchase: (
            <ModalPurchaseSquares
                close={() => handleModal(false)}
                data={data}
                setters={setters}
            />
        ),
        notice: <ModalNotice close={() => handleModal(false)} data={data} />,
        announcement: <ModalAnnouncement close={() => handleModal(false)} />,
    };

    const MODAL_HEADER = {
        save: false,
        notice: false,
        announcement: "Announcement",
    };

    return ReactDOM.createPortal(
        <Modal
            isOpen={modal}
            contentLabel="Sign In Modal"
            closeTimeoutMS={200}
            onRequestClose={() => handleModal(false)}
            shouldCloseOnOverlayClick={true}
            shouldFocusAfterRender={false}
            className="osiris"
        >
            {MODAL_HEADER[modalType] && (
                <div className="modal-header">
                    <button
                        onClick={() => handleModal(false)}
                        className="close"
                    >
                        <CloseCircleIcon />
                    </button>
                    <h2>{MODAL_HEADER[modalType]}</h2>
                </div>
            )}
            {MODAL_TYPES[modalType]}
        </Modal>,
        document.querySelector("#modal-root")
    );
};

export default GlobalModal;
