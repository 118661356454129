import React, { useState, useEffect } from "react";
import axios from "axios";

import { ReactComponent as SuccessIcon } from "assets/images/success.svg";
import { ReactComponent as RemoveIcon } from "assets/images/remove.svg";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.PUBLIC_URL;

function ModalPurchaseSquares(props) {
    const [status, setStatus] = useState("loading");

    const statuses = {
        loading: {
            note: (
                <>
                    Processing... <br />
                    <span className="subnote">
                        We are processing your request. Please wait.
                    </span>
                </>
            ),
            button: false,
            icon: <div className="spinning"></div>,
        },
        success: {
            note: "Success!",
            button: (
                <button
                    className={`button button--inverted button--hollow button--hollow--inverted `}
                    onClick={props.close}
                >
                    <span className="--text">DONE</span>
                </button>
            ),
            icon: <SuccessIcon />,
        },
        error: {
            note: "Encountered an error. Please try again later",
            button: (
                <button
                    className={`button button--inverted button--hollow button--hollow--inverted `}
                    onClick={props.close}
                >
                    <span className="--text">CLOSE</span>
                </button>
            ),
            icon: <RemoveIcon />,
        },
    };

    // Functions
    const handlePurchase = () => {
        if (props.data.data) {
            console.log({
                ...props.data,
                ...{
                    walletAddress: localStorage.getItem("publicAddress"),
                },
            });
            axios
                .post(
                    `${BACKEND_API_URL}/api/squares`,
                    {
                        ...props.data,
                        ...{
                            walletAddress:
                                localStorage.getItem("publicAddress"),
                        },
                    },
                    {
                        headers: {
                            sessionId: localStorage.getItem("session-id"),
                        },
                    }
                )
                .then(function (data) {
                    props.setters.handleClearSelection();
                    setStatus("success");

                    axios
                        .get(`${BACKEND_API_URL}/api/allsquares`, {
                            headers: {
                                sessionId: localStorage.getItem("session-id"),
                            },
                        })
                        .then(function (data) {
                            if (data.data.purchasedSquares) {
                                props.setters.setPurchasedSquares(
                                    data.data.purchasedSquares
                                );
                            }
                        })
                        .catch(function (data) {
                            if (data.response) {
                                console.log("error response: ", data.response);
                            }
                        });
                })
                .catch(function (data) {
                    if (data) {
                        console.log(
                            "purchase square response: ",
                            data.response
                        );
                    }
                });
        }
    };

    useEffect(() => {
        handlePurchase();
    }, []);
    return (
        <>
            <div className="modal-body text-center confirmation-modal">
                <div className="img-container">{statuses[status].icon}</div>

                <p className="note">{statuses[status].note}</p>
                {statuses[status].button}
            </div>
        </>
    );
}

export default ModalPurchaseSquares;
