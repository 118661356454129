import icon from "assets/images/preloader-logo.svg";
import textLogo from "assets/images/preloader-logo-text.svg";

function Loader() {
    return (
        <>
            <div id="preloader">
                <div id="ctn-preloader" className="ctn-preloader">
                    <div className="round_spinner">
                        <div className="spinner"></div>
                        <div className="text">
                            <img src={icon} alt="Your Earth Icon" />
                        </div>
                    </div>
                    <img src={textLogo} alt="Your Earth Text" />
                    <p></p>
                </div>
            </div>
        </>
    );
}

export default Loader;
