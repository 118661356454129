import { useState } from "react";

function useModal() {
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [data, setData] = useState(false);
    const [setters, setSetters] = useState(false);

    let handleModal = (props) => {
        if (props) {
            if (props.type) {
                setModalType(props.type);
            }

            if (props.data) {
                setData(() => props.data);
            }

            if (props.setters) {
                setSetters(props.setters);
            }
        }

        setModal(!modal);
    };

    return {
        modal,
        handleModal,
        modalType,
        data,
        setters,
    };
}

export default useModal;
