import React from "react";
import useModal from "./useModal";
import Modal from "context/modal/Modal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
    let { modal, handleModal, modalType, data, setters } = useModal();

    return (
        <Provider
            value={{
                modal,
                handleModal,
                modalType,
                data,
                setters,
            }}
        >
            <Modal />
            {children}
        </Provider>
    );
};

export { ModalContext, ModalProvider };
