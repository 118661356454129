import axios from "axios";
import { useEffect, useRef } from "react";

const DAPPX_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : process.env.PUBLIC_URL;

export const getMarketPrices = async () => {
  await axios
    .get(DAPPX_API_URL + '/users/getMarketPrices')
    .then(prices => setCookieToken('marketPrices', JSON.stringify(prices.data), 60))
    .catch(error => {
      console.error('getMarketPrices error:', error)
    })
}

// returns the cookie with the given name,
// or undefined if not found
export const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ))

  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const setCookieToken = (name, value, duration = 1209600) => {
  // token expiration is set to 1 min 
  document.cookie = `${name}=${value}; max-age=${duration};`
}

export const deletePricesCookie = () => {
  // token expiration is set to negative (instant delete)
  document.cookie = `marketPrices=; max-age=-1; secure`
}

export const deleteCookieToken = () => {
  // token expiration is set to negative (instant delete)
  document.cookie = `token=; max-age=-1; secure`;
};


// use setInterval
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const formatFloats = (num, dec = 6) => {
  return new Number(parseFloat(num).toFixed(dec)).toString()
}