import React, { useState } from "react";
import yourEarthVid from "assets/video/YourEarth_NewVideo.mp4";
import ReactPlayer from "react-player/lazy";
import { setCookieToken } from "utils";

// GET ENV
const current_env = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : "dev";

function ModalAnnouncement({ close }) {
    const [playing, setPlaying] = useState(false);

    // postponed sales cookie duration
    const [cookieDuration, setCookieDuration] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleVideoControl = (val) => {
        setPlaying(val);
    };

    return (
        <>
            <div className="modal-body text-center connect-wallet announcement">
                <div className="row inline-flex flex-wrap justify-center desktop">
                    <div className="announcement-container">
                        <ReactPlayer
                            className="react-player fixed-bottom"
                            url={yourEarthVid}
                            width="100%"
                            height="auto"
                            controls={true}
                            playing={playing}
                            onPlay={() => handleVideoControl(true)}
                            onPause={() => handleVideoControl(false)}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList:
                                            "nodownload noplaybackrate",
                                        disablePictureInPicture: true,
                                    },
                                },
                            }}
                        />
                        <div className="message">
                            <p>
                                We are building a meta-application ecosystem
                                where Acent technology and its various
                                innovations are integrated and driven by the
                                Your Earth DAO (YED) metaverse.
                            </p>
                            <p>
                                We decided to first introduce the YED’s land
                                sale model so that <span className="highlighted">it can be browsed before the
                                actual land sale begins</span>. This is based on the
                                suggestions and requests of many followers of
                                our project. This will enable potential users to
                                explore YED’s vast land, thereby, allowing them
                                to make a reasonable choice to participate in
                                our platform. We also can extend the browsing
                                period of the YED land if the public so
                                requests.It is hoped that YED will evolve beyond
                                the series of interchanges, business models,
                                games, and ways of working through a variety of
                                sophisticated business models and Internet
                                services towards becoming the new immersive
                                “life service platform” that advocates the earth
                                of life. Additional <span className="highlighted">official updates will be
                                announced via our official Twitter account</span>.
                            </p>
                            <p>Thank you.</p>
                        </div>
                    </div>
                </div>

                <div className="dont-show-container">
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            id="checkbox"
                            defaultChecked={checked}
                            onChange={(e) => {
                                setChecked(!checked);
                                setCookieDuration(e.target.checked);
                            }}
                        />
                        <label htmlFor="checkbox">
                            Don't show this for the next 14 days.
                        </label>
                    </div>
                    <button
                        className="button button--inverted button--hollow button--hollow--inverted button--postponed button--primary"
                        onClick={() => {
                            if (cookieDuration) {
                                console.log("current_env", current_env);
                                if (
                                    current_env === "uat" ||
                                    current_env === "production"
                                ) {
                                    setCookieToken('salesPostponed', true);
                                } else {
                                    setCookieToken('salesPostponed', true, 60);
                                }
                            }
                            close();
                        }}
                    >
                        DONE
                    </button>
                </div>
            </div>
        </>
    );
}

export default ModalAnnouncement;
