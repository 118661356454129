function Loader() {
    return (
        <>
            <div className="announcement-container">
                <div className="content">
                    <span className="label">Announcement: </span>
                    Earth Land NFTs sales schedule will be announced in our social media channels.
                </div>
            </div>
        </>
    );
}

export default Loader;
