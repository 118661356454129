import React from "react";
import logo from "assets/images/logo.svg";

function Logo(props) {
    const homeUrl = process.env.REACT_APP_LANDING_PAGE
        ? process.env.REACT_APP_LANDING_PAGE
        : "./";

    return (
        <>
            <div className="-logo">
                <div className="-link">
                    <a href={`${homeUrl}`}>
                        <img
                            className="logo-img"
                            src={logo}
                            alt="Planet Earth Logo"
                        />
                    </a>
                </div>
            </div>
        </>
    );
}

export default Logo;
