import React, { Suspense } from "react";
import "./assets/scss/default.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ModalProvider } from "context/modal/ModalContext";
import Loader from "components/Loader";
import Header from "components/Header";
import Footer from "components/Footer";
import Announcement from "components/Announcement";
const Map = React.lazy(() => import("pages/Map"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
import { isMobile } from "react-device-detect";

function App() {
    if (isMobile) {
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE}`;
    }
    return (
        <div>
            <Router basename={`${process.env.PUBLIC_URL}`}>
                <Suspense fallback={<Loader />}>
                    <ModalProvider>
                        <Header />
                        <Switch>
                            <Route exact path={`/`}>
                                <Announcement />
                                <Map />
                            </Route>
                            <Route path={`/dashboard`}>
                                <Dashboard />
                            </Route>
                        </Switch>
                        <Footer />
                    </ModalProvider>
                </Suspense>
            </Router>
        </div>
    );
}

export default App;
