import React, { useEffect, useState, useContext } from "react";

import axios from "axios";

import Logo from "components/common/Logo";
import Dropdown from "components/common/Dropdown";
import { ReactComponent as UserIcon } from "assets/images/user-circle.svg";
import { ReactComponent as AcentIcon } from "assets/images/acent.svg";

import { useLocation } from "react-router-dom";

const APP_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : process.env.PUBLIC_URL;

const homeUrl = process.env.REACT_APP_LANDING_PAGE
    ? process.env.REACT_APP_LANDING_PAGE
    : "./";

const Header = (props) => {
    const [isReady, setIsReady] = useState(false);
    const [publicAddress, setPublicAddress] = useState(false);
    const [balance, setBalance] = useState(0);
    const [userDetail, setUserDetail] = useState(0);
    const [isMap, setIsMap] = useState(false);

    let routeLocation = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (query.get("session")) {
            localStorage.setItem("session-id", query.get("session"));
            history.pushState({}, null, process.env.PUBLIC_URL);
        }

        executeConnect();

        if (routeLocation.pathname === "/") {
            setIsMap(true);
        }
    }, []);

    // Functions
    async function executeConnect() {
        if (!localStorage.getItem("session-id")) {
            return;
        }

        if (!window.ethereum) {
            console.log("Please install Metawallet first.");
        } else {
            try {
                localStorage.setItem("signincalled", "true");
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });

                if (accounts[0]) {
                    setPublicAddress(accounts[0]);
                    setIsReady(true);
                }
            } catch (error) {
                console.log(
                    "We have recorded an attempt to access your wallet. Please unlock your metawallet and try again."
                );
                return;
            }
        }
        return;
    }

    useEffect(() => {
        if (!isReady) {
            return;
        }

        axios
            .get(APP_URL + "/users/userProfile", {
                headers: {
                    "session-id": localStorage.getItem("session-id"),
                },
            })
            .then(function (data) {
                if (publicAddress !== data.data.public_address) {
                    localStorage.removeItem("username");
                    localStorage.removeItem("publicAddress");
                    localStorage.removeItem("balance");
                    localStorage.removeItem("session-id");

                    return;
                }

                localStorage.setItem(
                    "username",
                    data.data.username ? data.data.username : data.data.email_id
                );

                localStorage.setItem(
                    "publicAddress",
                    data.data.public_address ? data.data.public_address : ""
                );

                setUserDetail({
                    username: data.data.username
                        ? data.data.username
                        : data.data.email_id,
                    public_address: data.data.public_address,
                    referral_code: data.data.referral_code,
                });

                axios
                    .get(APP_URL + "/users/acent/active/balance", {
                        headers: {
                            "session-id": localStorage.getItem("session-id"),
                        },
                    })
                    .then(function (data) {
                        setBalance(parseFloat(data.data.balance.toFixed(6)));
                    })
                    .catch(function (data) {
                        console.log("getting user balance error: ", data);
                    });
            })
            .catch(function (data) {
                console.log("getting user details error: ", data);

                localStorage.removeItem("username");
                localStorage.removeItem("publicAddress");
                localStorage.removeItem("balance");
                localStorage.removeItem("session-id");
            });
    }, [isReady]);

    useEffect(() => {
        localStorage.setItem("balance", balance);
    }, [balance]);

    const handleLogout = () => {
        axios
            .get(APP_URL + "/users/logout", {
                headers: {
                    "session-id": localStorage.getItem("session-id"),
                },
            })
            .then(function (data) {
                localStorage.setItem("loggedin", false);
                handleEventsAfterLoggingOut();
            })
            .catch(function (data) {
                console.log("loggedout data error: ", data);
                handleEventsAfterLoggingOut();
            });
    };

    const handleEventsAfterLoggingOut = () => {
        localStorage.clear();
        window.location.reload();
        console.log("logout");
    };

    const goToProfile = () => {
        window.location.href = process.env.PUBLIC_URL + "/dashboard";
    };

    const goToSettings = () => {
        const DAPPX_URL = process.env.REACT_APP_DAPPX_URL
            ? process.env.REACT_APP_DAPPX_URL
            : process.env.PUBLIC_URL;
        window.location.href = DAPPX_URL + "/user-profile";
    };

    const userDropdown = [
        {
            label: "User Profile",
            name: "user-profile",
            onClick: goToProfile,
        },
        {
            label: "User Settings",
            name: "user-settings",
            onClick: goToSettings,
        },
        {
            label: "Logout",
            name: "logout",
            onClick: handleLogout,
        },
    ];

    const formatToCurrency = (value) => {
        return parseFloat(Number(value)).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 6,
        });
    };

    return (
        <>
            <div className="header">
                <div className="content">
                    <Logo collapsible />
                    <ul>
                        <li className="item-menu">
                            {isMap ? (
                                <></>
                            ) : (
                                <a
                                    href={`${process.env.PUBLIC_URL}/`}
                                    className={isMap ? "active" : ""}
                                >
                                    <span>MAP</span>
                                </a>
                            )}
                        </li>
                        {userDetail ? (
                            <li className="balance">
                                <AcentIcon /> {formatToCurrency(balance)} ACE
                            </li>
                        ) : (
                            ""
                        )}

                        <li className="user-menu">
                            {userDetail ? (
                                <Dropdown
                                    containerClass={"input-content"}
                                    btnClass={"input-dropdown"}
                                    label={
                                        userDetail ? (
                                            <>
                                                <div className="icon-container">
                                                    <UserIcon />
                                                </div>
                                                {/* <span>
                                                    {userDetail.username}
                                                </span> */}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                    items={userDropdown}
                                />
                            ) : (
                                <a href={homeUrl}>
                                    <span>{"Log in"}</span>
                                </a>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Header;
