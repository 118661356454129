import React from "react";

import {
    FaTelegramPlane,
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaDiscord,
} from "react-icons/fa";

function SocialMedia() {
    const socialMediaList = [
        {
            name: "telegram",
            icon: <FaTelegramPlane />,
            class: "number-1",
            link: "https://t.me/AcentOfficialChat",
        },
        {
            name: "telegram-2",
            icon: <FaTelegramPlane />,
            class: "number-2",
            link: "https://t.me/AcentChannel",
        },
        {
            name: "facebook",
            icon: <FaFacebookF />,
            link: "https://www.facebook.com/FBACENT",
        },
        {
            name: "twitter",
            icon: <FaTwitter />,
            link: "https://www.clkmg.com/acent639/ts398",
        },
        {
            name: "instagram",
            icon: <FaInstagram />,
            link: "https://bit.ly/3pIwsI7",
        },
        {
            name: "discord",
            icon: <FaDiscord />,
            link: "https://discord.gg/et2fZJWGge",
        },
    ];

    return (
        <>
            <ul className="-social">
                {socialMediaList.length !== 0
                    ? socialMediaList.map((value, id) => (
                          <li key={value.name}>
                              {value.link ? (
                                  <a
                                      href={value.link}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={value.class}
                                  >
                                      {value.icon}
                                  </a>
                              ) : (
                                  <button>
                                      <span className={value.class}>
                                          {value.icon}
                                      </span>
                                  </button>
                              )}
                          </li>
                      ))
                    : ""}
            </ul>
        </>
    );
}

export default SocialMedia;
