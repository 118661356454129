import React from "react";
import Logo from "components/common/Logo";
import SocialMedia from "components/common/SocialMedia";

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="content">
                    <Logo collapsible />
                    <p style={{fontSize: '14px'}}>© 2022 All Rights Reserved.</p>
                    <SocialMedia />
                </div>
            </div>
        </>
    );
};

export default Footer;
