import React, { useEffect, useRef, useState } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

function Dropdown(props) {
    const ref = useRef();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [ddStyle, setDdStyle] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    return (
        <>
            <div
                className={`input-group dropdown ${
                    isMenuOpen ? "active " : ""
                } ${props.containerClass}`}
                ref={ref}
                key={props.key}
            >
                <div className="input-group-prepend">{props.prepend}</div>

                <button
                    className={props.btnClass}
                    type="button"
                    onClick={(e) => {
                        setIsMenuOpen((oldState) => !oldState);
                    }}
                >
                    {props.label}
                    {isMenuOpen ? <MdArrowDropUp /> : <MdArrowDropDown />}
                </button>

                {props.items ? (
                    <ul
                        role="menu"
                        className="dropdown-menu"
                        style={ddStyle ? ddStyle : null}
                    >
                        {props.items.map((item, index) => (
                            <li
                                className={`menu-item ${
                                    item.disabled ? "disabled" : ""
                                }`}
                                key={"mobile-sub-menu-" + index}
                            >
                                <button
                                    title={item.name}
                                    className="menu-link"
                                    type="button"
                                    onClick={() => {
                                        if (!props.evnt) {
                                            item.onClick();
                                        } else {
                                            props.evnt(
                                                item.column,
                                                item.value,
                                                item.label
                                            );
                                        }
                                        setTimeout(setIsMenuOpen(false), 1000);
                                    }}
                                >
                                    {item.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </>
    );
}

export default Dropdown;
