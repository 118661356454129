import React, { useState, useEffect } from "react";
import axios from "axios";

import { ReactComponent as ConfirmIcon } from "assets/images/confirm.svg";
import { ReactComponent as SuccessIcon } from "assets/images/success.svg";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.PUBLIC_URL;

function ModalSaveSquare(props) {
    const [status, setStatus] = useState("confirm");

    const statuses = {
        confirm: {
            note: "Are you sure you want to save?",
            button: (
                <div className="button-list-container">
                    <button
                        className={`button button--inverted button--hollow button--hollow--inverted `}
                        onClick={() => handleSave()}
                    >
                        <span className="--text">SAVE</span>
                    </button>
                    <button
                        className={`button button--inverted button--hollow button--hollow--inverted `}
                        onClick={props.close}
                    >
                        <span className="--text">CANCEL</span>
                    </button>
                </div>
            ),
            icon: <ConfirmIcon />,
        },
        loading: {
            note: "Processing...",
            button: false,
            icon: <div className="spinning"></div>,
        },
        success: {
            note: "Success!",
            button: (
                <button
                    className={`button button--inverted button--hollow button--hollow--inverted `}
                    onClick={props.close}
                >
                    <span className="--text">DONE</span>
                </button>
            ),
            icon: <SuccessIcon />,
        },
        error: {
            note: "Encountered an error. Please try again later",
            button: (
                <button
                    className={`button button--inverted button--hollow button--hollow--inverted `}
                    onClick={props.close}
                >
                    <span className="--text">CLOSE</span>
                </button>
            ),
            icon: "X",
        },
    };

    // Functions
    const handleSave = () => {
        console.log(props);
        setStatus("loading");
        axios
            .post(
                `${BACKEND_API_URL}/adminapi/area`,
                props.data
                // {
                //     headers: {
                //         "session-id":
                //             localStorage.getItem("session-id"),
                //     },
                // }
            )
            .then(function (response) {
                console.log(response);

                localStorage.removeItem("selected-squares");
                props.setters.setSelectedSquares(0);

                axios
                    .get(`${BACKEND_API_URL}/adminapi/area`)
                    .then(function (response) {
                        if (response.data) {
                            props.setters.setSpecialSquares(response.data);
                            setStatus("success");

                            return true;
                        }
                    })
                    .catch(function (response) {
                        if (response.response) {
                            console.log(
                                "updating all squares response: ",
                                response.response
                            );
                            setStatus("error");
                            return false;
                        }
                    });
            })
            .catch(function (response) {
                if (response) {
                    console.log(
                        "set sellable square response: ",
                        response.response
                    );
                    setStatus("error");
                    return false;
                }
            });
    };

    useEffect(() => {
        console.log(status);
    }, [status]);
    return (
        <>
            <div className="modal-body text-center confirmation-modal">
                <div className="img-container">{statuses[status].icon}</div>

                <p className="note">{statuses[status].note}</p>
                {statuses[status].button}
            </div>
        </>
    );
}

export default ModalSaveSquare;
